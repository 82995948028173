import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { FormUtil, FormOutType } from 'src/app/utils/form.util';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { TypeForm } from 'src/app/utils/type-form';
import { FilterPageType } from './filter-settings/filter-settings.component';

export interface AoFilterHeaderSelect {
    value: any;
    name: string | null;
}

export interface AoFilterHeaderSettings {
    defaultLimit: number;
    fields: AoFilterHeaderField[];
}

export interface AoFilterHeaderField {
    placeholder: string;
    name: string;
    searchType: FormOutType;
    selectValues?: AoFilterHeaderSelect[];
    sortKey?: any;
    sortState?: 'desc' | 'asc';
    span?: number;
    locked?: boolean;
}

@Component({
    selector: 'app-ao-filter-header-list',
    templateUrl: './ao-filter-list-header.component.html',
    styleUrls: ['./ao-filter-list-header.component.scss'],
})
export class AoFilterListHeaderComponent implements OnInit, OnDestroy {
    @Input() showSelect = true;
    @Input() showFilterSettings = false;
    @Input() settings!: AoFilterHeaderSettings;

    @Input() ids: string[] = [];

    private _allSelected = false;
    get allSelected() {
        return this._allSelected;
    }
    @Input() set allSelected(value) {
        this._allSelected = value;
        this.onSelectAll();
    }

    @Input() selected: string[] = [];
    @Output() selectedChange = new EventEmitter<string[]>();

    @Input() filter: any;
    @Output() filterChange = new EventEmitter<any>();

    @Input() skip = 0;
    @Output() skipChange = new EventEmitter<number>();

    @Input() limit = 0;
    @Output() limitChange = new EventEmitter<number>();

    @Input() sort!: { sortKey: any; sortType: 'desc' | 'asc' };

    @Input() pageType?: FilterPageType;

    @Output() sortChange = new EventEmitter<{ sortKey: any; sortType: 'desc' | 'asc' }>();

    @Output() allSelectedChange = new EventEmitter<boolean>();

    @Output() hiddenFiltersChange = new EventEmitter<string[]>();

    form!: TypeForm;

    range: UntypedFormGroup = new UntypedFormGroup({
        startDate: new UntypedFormControl(''),
        endDate: new UntypedFormControl(''),
    });

    private formUtil!: FormUtil;
    private formSub!: Subscription;

    width = 'calc(100% - 0px)';
    spans = 0;

    public hiddenFilters: string[] = [];

    private readonly destroy = new Subject<void>();

    ngOnInit(): void {
        this.limit = this.settings.defaultLimit;

        if (this.showSelect && this.showFilterSettings) {
            this.width = 'calc(100% - 90px)';
        } else if (this.showSelect) {
            this.width = 'calc(100% - 50px)';
        } else if (this.showFilterSettings) {
            this.width = 'calc(100% - 40px)';
        } else {
            this.width = 'calc(100% - 0px)';
        }

        this.form = new TypeForm({});
        for (const field of this.settings.fields) {
            if (field.span) {
                this.spans += field.span - 1;
            }

            this.form.addControl(
                field.name,
                new UntypedFormControl(this.filter[field.name] ? this.filter[field.name] : ''),
            );

            if (field.searchType === FormOutType.DATE_RANGE && this.filter[field.name]) {
                const start = this.range.get('startDate');
                const end = this.range.get('endDate');

                if (start && end) {
                    start.setValue(this.filter[field.name].startDate, { emitEvent: false });
                    end.setValue(this.filter[field.name].endDate, { emitEvent: false });
                }
            }
        }

        this.formUtil = new FormUtil(this.form);

        this.subscribeChanges();
    }

    ngOnDestroy(): void {
        this.formSub.unsubscribe();
        this.destroy.next();
        this.destroy.complete();
    }

    //TODO
    // in evaluation component verwendet ist aber ggf. unnnötig (?)
    // später evtl. weg wenn man evaluation component neu macht
    setFormValues(): void {
        this.formSub.unsubscribe();
        setTimeout(() => {
            Object.keys(this.form.controls).forEach((key) => {
                this.form.get(key)?.setValue(this.filter[key] ? this.filter[key] : '', { emitEvent: false });
            });

            setTimeout(() => {
                this.subscribeChanges();
            });
        });
    }

    subscribeChanges(): void {
        this.formSub = this.form.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => {
            this.changes();
        });
        this.range.valueChanges
            .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy))
            .subscribe(() => {
                if (this.range.valid && this.range.value.startDate && this.range.value.endDate) {
                    this.changes();
                }
            });
    }

    changes(): void {
        this.skip = 0;
        this.limit = this.settings.defaultLimit;
        this.skipChange.emit(this.skip);
        this.limitChange.emit(this.limit);
        this.filter = this.createFilterMap();
        this.filterChange.emit(this.filter);
    }

    get formOutType() {
        return FormOutType;
    }

    onSelectAll(): void {
        if (!this.allSelected) {
            this.selected = [];
        } else {
            this.selected = this.ids;
        }

        this.allSelectedChange.emit(this.allSelected);

        this.selectedChange.emit(this.selected);
    }

    onSort(field: AoFilterHeaderField): void {
        if (!field.sortState) {
            field.sortState = 'asc';
        } else if (field.sortState === 'asc') {
            field.sortState = 'desc';
        } else {
            field.sortState = undefined;
        }

        for (const fi of this.settings.fields) {
            if (fi.name !== field.name) {
                fi.sortState = undefined;
            }
        }

        if (field.sortState && field.sortKey) {
            this.sort = { sortKey: field.sortKey, sortType: field.sortState };
        }

        this.sortChange.emit(this.sort);
    }

    private createFilterMap(): any {
        const result: any = {};
        for (const field of this.settings.fields) {
            if (field.searchType === FormOutType.DATE_RANGE) {
                if (this.range.get('startDate')?.value && this.range.get('endDate')?.value) {
                    result[field.name] = this.range.value;
                }
            } else {
                result[field.name] = this.formUtil.getValue({ key: field.name, outType: field.searchType });
            }
        }

        return result;
    }

    filterSelected(filter?: any, emit = true): void {
        if (!filter) {
            this.form.reset({}, { emitEvent: false });
            this.range.reset({}, { emitEvent: false });
        } else {
            this.form.reset({}, { emitEvent: false });
            this.range.reset({}, { emitEvent: false });

            for (const field of this.settings.fields) {
                let value: any = '';
                if (
                    this.isEmpty(filter[field.name]) &&
                    field.selectValues?.length &&
                    field.searchType === FormOutType.STRING
                ) {
                    filter[field.name] = null;
                }

                if (!this.isEmpty(filter[field.name])) {
                    value = filter[field.name];
                }

                if (field.searchType === FormOutType.DATE_RANGE && filter[field.name]) {
                    const start = this.range.get('startDate');
                    const end = this.range.get('endDate');

                    if (start && end) {
                        start.setValue(filter[field.name].startDate, { emitEvent: false });
                        end.setValue(filter[field.name].endDate, { emitEvent: false });
                    }
                }

                this.form.get(field.name)?.setValue(value, { emitEvent: false });
            }
        }

        this.filter = filter ?? {};

        if (emit) {
            this.filterChange.emit(this.filter);
        }
    }

    private isEmpty(value: any): boolean {
        return value === null || value === undefined || value === '';
    }
}
