import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AoFilterHeaderField } from '../ao-filter-list-header.component';
import { Subject, combineLatest, startWith, takeUntil } from 'rxjs';

@Component({
    selector: 'app-filter-date-range',
    templateUrl: './filter-date-range.component.html',
    styleUrls: ['./filter-date-range.component.scss'],
})
export class FilterDateRangeComponent implements OnInit, OnDestroy {
    @Input() field!: AoFilterHeaderField;
    @Input() range!: UntypedFormGroup;

    @Output() sortChange = new EventEmitter<void>();
    @Output() resetRange = new EventEmitter<void>();

    public active = false;
    private readonly _destroy = new Subject<void>();

    clear(): void {
        this.range.reset();
        this.resetRange.emit();
    }

    ngOnInit(): void {
        const startDateControl = this.range.get('startDate');
        const endDateControl = this.range.get('endDate');
        if (startDateControl && endDateControl) {
            combineLatest([startDateControl.valueChanges, endDateControl.valueChanges])
                .pipe(takeUntil(this._destroy), startWith([startDateControl.value, endDateControl.value]))
                .subscribe(([startDate, endDate]) => {
                    this.active = startDate && endDate;
                });
        }
    }

    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }
}
